import React, { useEffect, useState } from 'react'
import Newheader from '../../directives/newheader'
import productdetail from "../../assets/images/banner/productdetail.png";
import { Col, Container, Row } from 'react-bootstrap';
import Footer from '../../directives/footer';
import { BASE_URL } from '../../Constant/Index';
import axios from 'axios';
import Breadcrumbs from '../../directives/bradcrumps';
import DocumentMeta from "react-document-meta";
function Privacypolicy() {
  useEffect (() => {
    TitleMetakeyword()
  }, [])
  const [titlemegakeyword,setTitleMegaKeyword]=useState([])
const [desmegakeyword,setDesMegaKeyword]=useState([])
const [keymegakeyword,setKeyMegaKeyword]=useState([])

  const TitleMetakeyword = async () => {
    try {
      const response = await fetch(`${BASE_URL}/auth/seo_setting`);
      const jsonData = await response.json();
      const ALLData= jsonData.data;
      console.log("ALLData",ALLData);
      const SinglepageData = ALLData.filter(items => items.page === "PrivacyPolicy");
      setTitleMegaKeyword(SinglepageData[0].title);
      setDesMegaKeyword(SinglepageData[0].description);
      setKeyMegaKeyword(SinglepageData[0].keyword);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const meta = {
    title:titlemegakeyword,
    description:desmegakeyword,
    canonical: "https://admin.canineproducts.in/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:keymegakeyword,      },
    },
  };

  const [privacyPolicy, setPricacyPolicy] = useState([]);
  useEffect(() => {
    allPrivacypolicy();
  }, []);
  const allPrivacypolicy = async () => {
    axios
      .get(`${BASE_URL}/auth/privacy_policy`)
      .then((response) => {
        console.log(response);
        setPricacyPolicy(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
     <DocumentMeta {...meta}>
      <Newheader />
      <Breadcrumbs/>

      <Container fluid className="p-0">
        <div className="all-bg">
          <img src={productdetail} />
        </div>
      </Container>
      <section className='section-padding'>
        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
            <h1 className='text-center'><strong>Privacy Policy </strong></h1>
              <div>
                {privacyPolicy.map((policy, index) => (
                  <div key={index}>
                    <div dangerouslySetInnerHTML={{ __html: policy.value }} />
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
      </DocumentMeta>
    </>
  )
}

export default Privacypolicy