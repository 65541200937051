
import React, { useState, useEffect } from "react";
import Newheader from "../../directives/newheader";
import Footer from "../../directives/footer";
import { Container, Row, Col, Button } from "react-bootstrap";
import { BASE_URL } from "../../Constant/Index";
import about from "../../assets/images/banner/banner.png";
import { Link } from "react-router-dom";
import loadinggif from "../../assets/images/video/loading.gif";
import ReactPaginate from "react-paginate";
import DocumentMeta from "react-document-meta";
import Breadcrumbs from "../../directives/bradcrumps";

function Blog() {



  useEffect (() => {
    TitleMetakeyword()
  }, [])
  const [titlemegakeyword,setTitleMegaKeyword]=useState([])
const [desmegakeyword,setDesMegaKeyword]=useState([])
const [keymegakeyword,setKeyMegaKeyword]=useState([])

  const TitleMetakeyword = async () => {
    try {
      const response = await fetch(`${BASE_URL}/auth/seo_setting`);
      const jsonData = await response.json();
      const ALLData= jsonData.data;
      console.log("ALLData",ALLData);
      const SinglepageData = ALLData.filter(items => items.page === "Blog");
      setTitleMegaKeyword(SinglepageData[0].title);
      setDesMegaKeyword(SinglepageData[0].description);
      setKeyMegaKeyword(SinglepageData[0].keyword);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const meta = {
    title:titlemegakeyword,
    description:desmegakeyword,
    canonical: "https://admin.canineproducts.in/",
    meta: {
      charset: "utf-8",
      name: {
        keywords:keymegakeyword,      },
    },
  };
  const [blog, setblog] = useState([]);
  const [showFullParagraph, setShowFullParagraph] = useState(false);
  const [paragraphData, setParagraphData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const fetchBlogs = async () => {
    try {
      const response = await fetch(`${BASE_URL}/auth/blog`);
      const data = await response.json();
      const reversedata = data.data.reverse();
      setblog(reversedata);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    fetchBlogs().then(() => {
      setLoading(false);
    });
  }, []);

  const renderBlogDescription = (description) => {
    const removeHTMLTags = (html) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      return doc.body.textContent || "";
    };

    const plainTextDescription = removeHTMLTags(description);
    const maxCharacters = 350;

    if (plainTextDescription.length <= maxCharacters) {
      return <p>{plainTextDescription}</p>;
    }

    const truncatedDescription = plainTextDescription.slice(0, maxCharacters);

    return <p>{truncatedDescription}......</p>;
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentBlogItems = blog.slice(offset, offset + itemsPerPage);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (searchQuery.length > 0) {
     
      const filteredProducts = blog.filter((product) => {
        const lowerSearchQuery = searchQuery.toLowerCase();
        return (
          (product.title &&
            product.title.toLowerCase().includes(lowerSearchQuery)) ||
          (product.description &&
            product.description.toLowerCase().includes(lowerSearchQuery)) ||
          (product.sub_category &&
            product.sub_category.toLowerCase().includes(lowerSearchQuery)) ||
          (product.category_ids &&
            product.category_ids.toLowerCase().includes(lowerSearchQuery)) ||
          (product.brand_id &&
            product.brand_id.toLowerCase().includes(lowerSearchQuery)) ||
          (product.lifeStage_id &&
            product.lifeStage_id.toLowerCase().includes(lowerSearchQuery)) ||
          (product.helthCondition_id &&
            product.helthCondition_id
              .toLowerCase()
              .includes(lowerSearchQuery)) ||
          (product.Petsbreeds_id &&
            product.Petsbreeds_id.toLowerCase().includes(lowerSearchQuery)) ||
          (product.price &&
            product.price.toLowerCase().includes(lowerSearchQuery)) ||
          (product.wholePrice &&
            product.wholePrice.toLowerCase().includes(lowerSearchQuery))
        );
      });

      setFilteredProducts(filteredProducts);
      console.log("=========>>>>>>>", filteredProducts);
    } else {
      setFilteredProducts([]);
    }
  }, [searchQuery, blog]);

  return (
    <>
     <DocumentMeta {...meta}> 
      <Newheader />
      {loading ? (
        <section className="section-padding mt-3 mb-3">
          <div className="loaderimg text-center text-black mb-4">
            <img src={loadinggif} alt="" />
            <h5>Please Wait.......</h5>
          </div>
        </section>
      ) : (
        <>
     <Breadcrumbs/>
          <Container fluid className="p-0">
            <div className="all-bg">
              <img src={about} alt="" />
            </div>
          </Container>
          
          <Container>
          <div className="text-right">
                <div className="search-bar">
                  <input
                    type="text"
                    placeholder="Type your pet-related question or topic of concern to find helpful articles and information below!"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    className="search-input"
                  />
                  <button type="button" className="search-button">
                    <i className="fa fa-search" />
                  </button>
                </div>
                {/* Your dropdown here */}
                <ul className="search-dropdown">
                  {filteredProducts.map((product, index) => (
                    <li key={index}>
                      <Link to={`/blog-details/${product.id}`}>
                        {product.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
          </Container>
          <section className="section-padding">
            <Container>
              <Row>
                {currentBlogItems.length > 0 ? (
                  currentBlogItems.map((item, index) => (
                    <Col lg={12} className="mb-4" key={item.id}>
                      <div className="blog-card-are">
                        <Row>
                          <Col sm={5}>
                            <img
                              src={
                                "https://admin.canineproducts.in//storage/app/public/blog/" +
                                item.image
                              }
                              alt=""
                            />
                          </Col>
                          <Col sm={7} className="align-self-center">
                            <div className="blog-cardContent">
                              <h4>{item.title}</h4>
                              <p>{renderBlogDescription(item.description)}</p>
                              <Link to={`/blog-details/${item.id}`}>
                                Read More
                              </Link>
                              <hr />
                              <Row>
                                <Col lg={8}>
                                  <div className="blog-comment">
                                    <Link>
                                      <i className="fa fa-user" /> {item.author}
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))
                ) : (
                  <p className="emptyMSG">No Blog Data.</p>
                )}
              </Row>
              <ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(blog.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </Container>
          </section>
        </>
      )}
      <Footer />
      <style jsx>{`
        .pagination .active a {
          color: black !important;
          background-color:#ffc934 !important;
          padding:9px;
        }
      `}</style>
      </DocumentMeta>
    </>
  );
}

export default Blog;
