import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const formatDisplayName = (name) => {
    // Replace hyphens with spaces
    let displayName = name.replace(/-/g, ' ');

    // Decode URI component to handle %20 and other encoded characters
    displayName = decodeURIComponent(displayName);

    // Optionally, handle numeric IDs or other specific cases
    if (/^\d+$/.test(displayName)) {
      displayName = ''; // Or handle differently if needed
    }

    return capitalize(displayName);
  };

  return (
    <div aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          const displayName = formatDisplayName(name);

          return isLast ? (
            <li key={routeTo} className="breadcrumb-item active" aria-current="page">
              {displayName}
            </li>
          ) : (
            <li key={routeTo} className="breadcrumb-item">
              <Link to={routeTo}>{displayName}</Link>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Breadcrumbs;
