import React, { useEffect, useState } from "react";
import Newheader from "../../directives/newheader";
import { Container, Row, Col, Table, Button, Form } from "react-bootstrap";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Footer from "../../directives/footer";
import axios from "axios";
// import star from "../star";
import { BASE_URL } from "../../Constant/Index";
import { Toaster, toast } from "react-hot-toast";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import { styled } from "styled-components";
import Lightbox from "react-awesome-lightbox";
import paydone from "../../assets/images/icon/paydone.png";
import voch from "../../assets/images/icon/voch.png";
import { Fade } from "react-reveal";
import { useCartWithoutLogin } from "../context/AddToCardWithoutLogin";
import { RWebShare } from "react-web-share";
import Breadcrumbs from "../../directives/bradcrumps";

function Productdetail() {
  const { id } = useParams();
  const [paymentId, setPaymentId] = useState("");
  const [productDetails, setProductDetails] = useState([]);
  const [suggestionDetails, setSuggestionDetails] = useState([]);
  console.log("suggestionDetails: ", suggestionDetails);
  console.log("productDetailsssss: ", productDetails?.suggestion_product);
  const [itemwiseonebanner, setitemwiseonebanner] = useState([]);
  const [addToCartStatus, setAddToCartStatus] = useState("");
  const [notifyMeData, setNotifyMeData] = useState("");
  const { stars, reviews } = Productdetail;
  const [quantity, setQuantity] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [selectedVariantPrice, setSelectedVariantPrice] = useState("");
  const [selectedVariantStock, setSelectedVariantStock] = useState("");
  const [comboData, setComboData] = useState([]);
  const loginType = localStorage.getItem("loginType");
  const vendorIDstore = localStorage.getItem("vendorID");
  const customerLoginId =
    loginType === "wholeseller"
      ? Number(localStorage.getItem("UserWholesellerId"))
      : localStorage.getItem("userInfo");
  const { cart, dispatch } = useCartWithoutLogin();
  const handleIncrementone = () => {
    if (productDetails?.variations?.length > 0) {
      productDetails?.variations.forEach((el) => {
        if (el?.type === selectedVariant) {
          if (quantity === el?.stock) {
            toast.error(`${el.type} Stock not avilable`);
          } else {
            setQuantity(quantity + 1);
          }
        } else {
        }
      });
    } else {
      if (quantity === productDetails?.stock) {
        toast.error(`Stock not avilable`);
      } else {
        setQuantity(quantity + 1);
      }
    }
  };
  const handleDecrementone = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  useEffect(() => {
    if (productDetails?.variations && productDetails?.variations.length > 0) {
      const defaultVariant = productDetails?.variations[0];
      setSelectedVariant(defaultVariant?.type);
      setSelectedVariantPrice(defaultVariant?.price);
      setSelectedVariantStock(defaultVariant.stock);
    }
  }, [productDetails]);

  useEffect(() => {
    productData();
    itemWiseBanner();

    fetchBreed();
    fetchLifestage();
    AllBanner();
    AllGetreviewList();
    couponlistdata();
    GetdataAll();
    allReview();
    allAddressList();
    // fetchProductData();
  }, [id]);

  const productData = async () => {
    axios
      // .get(`${BASE_URL}/items/details/${id}`)
      .get(`${BASE_URL}/items/product_details/${id}`)
      .then((response) => {
        setProductDetails(response.data.data);
        const cate = response.data.data.category_id;
        const subcate = response.data.data.sub_category;
        const responseData = response?.data?.data?.suggestion_product[0];
        let suggestionData = [];
        if (responseData) {
          for (let i = 0; i < 3; i++) {
            const element = i;
            if (i === 0) {
              const data = {
                ...responseData.product_1[0],
                combo_price: responseData.price_1,
                combo_variation: responseData.variation_1,
              };
              suggestionData.push(data);
            } else if (i === 1) {
              const data = {
                ...responseData.product_2[0],
                combo_price: responseData.price_2,
                combo_variation: responseData.variation_2,
              };
              suggestionData.push(data);
            } else if (i === 2) {
              if (responseData?.product_3?.length > 0) {
                const data = {
                  ...responseData.product_3[0],
                  combo_price: responseData.price_3,
                  combo_variation: responseData.variation_3,
                };
                suggestionData.push(data);
              }
            } else {
            }
          }
        }
        setComboData(suggestionData);
        console.log("comboData 111", suggestionData);
        console.log("comboData", comboData);

        fetchrelated(cate, subcate);
        // Perform any additional actions after successful deletion
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [allrelatedproduct, setallrelatedproduct] = useState([]);

  const fetchrelated = async (cate, subcate) => {
    axios
      .get(`${BASE_URL}/categories/subcategories`)
      .then((response) => {
        const allDaTa = response.data.data;
        const update = allDaTa.find((item) => item.name == subcate);
        const updated = update.id;

        fetchrelatedproduct(cate, updated);

        // Perform any additional actions after successful deletion
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchrelatedproduct = async (cate, updated) => {
    axios
      .get(`${BASE_URL}/items/product/${cate}/${updated}`)
      .then((response) => {
        setallrelatedproduct(response.data.data);
        // Perform any additional actions after successful deletion
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const customer_id = localStorage.getItem("userInfo");
  let storedUserId = JSON.parse(customer_id);
  useEffect(() => {
    localStorage.setItem(
      "savedCartItems",
      JSON.stringify([...addToCartStatus, productDetails])
    );
  }, [productDetails]);
  const handleAddToCart = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/customer/wish-list/add_product`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Set appropriate content type
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Headers": "Content-Type",
          },
          item_name: productDetails?.name,
          variant: selectedVariant.length > 0 ? selectedVariant : "", // You may need to update this based on your data
          image: productDetails?.image,
          quantity: quantity,
          total_quantity: selectedVariantStock
            ? selectedVariantStock
            : productDetails?.stock,
          return_order: productDetails?.returnable || "yes",
          price:
            calculatedPrice === 0
              ? parseInt(productDetails?.price) * quantity
              : parseInt(calculatedPrice),
          user_id: storedUserId,
          item_id: productDetails?.id,
        }
      );
      if (response) {
        if (response.data.status === "200") {
          toast.success("Added to cart!");

          // setAddToCartStatus("Added to cart!");
          shippingpage(`/cart/${id}`);
        } else {
          // setAddToCartStatus(response.data.message);
          toast.error("Already added");
        }
        // const updatedCart = [...addToCartStatus, productDetails];
        // setAddToCartStatus(updatedCart);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      setAddToCartStatus("Error adding to cart");
    }
    const modal = document.querySelector(".modal");
    if (modal) {
      modal.classList.remove("show");
      modal.style.display = "none";
      document.body.classList.remove("modal-open");
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    }
  };
  const handleComboAddToCart = async () => {
    let productData = [];
    const suggestionCombo = comboData ?? [];
    for (let i = 0; i < suggestionCombo.length; i++) {
      const element = suggestionCombo[i];
      const data = {
        item_name: element?.name,
        variant: element?.combo_variation,
        image: element?.image,
        quantity: 1,
        total_quantity: element?.stock,
        return_order: element?.returnable || "yes",
        price: element?.combo_price,
        user_id: storedUserId,
        item_id: element?.id,
        type: "combo",
        min_order: "",
        seller_id: "",
      };
      productData.push(data);
    }
    console.log("productData", productData);
    try {
      const response = await axios.post(
        `${BASE_URL}/customer/wish-list/add_comboproduct`,
        productData,
        {
          headers: {
            "Content-Type": "application/json", // Set appropriate content type
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Headers": "Content-Type",
          },
        }
      );
      if (response) {
        if (response.data.status === "200") {
          toast.success("Added to cart!");

          // setAddToCartStatus("Added to cart!");
          shippingpage(`/cart/${id}`);
        } else {
          // setAddToCartStatus(response.data.message);
          toast.error("Already added");
        }
        // const updatedCart = [...addToCartStatus, productDetails];
        // setAddToCartStatus(updatedCart);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      setAddToCartStatus("Error adding to cart");
    }
    // const modal = document.querySelector(".modal");
    // if (modal) {
    //   modal.classList.remove("show");
    //   modal.style.display = "none";
    //   document.body.classList.remove("modal-open");
    //   const modalBackdrop = document.querySelector(".modal-backdrop");
    //   if (modalBackdrop) {
    //     modalBackdrop.remove();
    //   }
    // }
  };

  // ****************notifyme
  const [email, setEmail] = useState("");
  const [variation, setVariation] = useState("");
  const [emailError, setEmailError] = useState("");
  const [variationError, setVariationError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const isEmailFormatValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(isEmailFormatValid(emailValue));
  };
  const handleNotifymeSubmit = async (e) => {
    e.preventDefault();

    // Prepare form data
    const notifymeData = new FormData();
    notifymeData.append("email", email);
    notifymeData.append("variation", variation);
    notifymeData.append("stock", productDetails.stock);
    notifymeData.append("user_id", storedUserId);
    notifymeData.append("item_id", productDetails.id);

    // Send a request
    axios
      .post(`${BASE_URL}/items/notify`, notifymeData)
      .then((response) => {
        toast.success("Your data was successfully added");
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
      });
  };

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity)) {
      setQuantity(newQuantity);
    }
  };

  const ratingStar = Array.from({ length: 5 }, (item, index) => {
    let number = index + 0.5;
    return (
      <span key={index}>
        {productDetails?.rating_count >= index + 1 ? (
          <FaStar className="icon" />
        ) : productDetails?.rating_count >= number ? (
          <FaStarHalfAlt className="icon" />
        ) : (
          <AiOutlineStar className="icon" />
        )}
      </span>
    );
  });

  const ratingStarSuggetstion = Array.from({ length: 5 }, (item, index) => {
    let number = index + 0.5;
    return (
      <span key={index}>
        {productDetails?.suggestion_product?.rating_count >= index + 1 ? (
          <FaStar className="icon" />
        ) : productDetails?.suggestion_product?.rating_count >= number ? (
          <FaStarHalfAlt className="icon" />
        ) : (
          <AiOutlineStar className="icon" />
        )}
      </span>
    );
  });

  const itemWiseBanner = async () => {
    try {
      const response = await fetch(`${BASE_URL}/categories/banner`);
      const data = await response.json();
      const latestPosts = data.data.slice(0, 2);
      setitemwiseonebanner(latestPosts);
    } catch (error) {
      console.log(error);
    }
  };

  const [tragetSpecies, setTragetSpecies] = useState([]);
  const fetchBreed = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/auth/breed/1`);
      const names = response.data.data.map((item) => item.name);
      setTragetSpecies(names);

      // Handle response as needed
    } catch (error) {
      console.error(error);
      // Handle error as needed
    }
  };
  const [ageRange, setAgeRange] = useState([]);
  const fetchLifestage = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/auth/life_stage/1`);
      const ageDog = response.data.data.map((item) => item.name);
      setAgeRange(ageDog);

      // Handle response as needed
    } catch (error) {
      console.error(error);
      // Handle error as needed
    }
  };

  const gradientColors = [
    "linear-gradient(180deg, #FFF0BA 0%, rgba(251.81, 233.11, 165.78, 0) 100%)",
    "linear-gradient(180deg, #C7EBFF 0%, rgba(199, 235, 255, 0) 100%)",
    "linear-gradient(180deg, #FECBF0 0%, rgba(254, 203, 240, 0) 100%)",
    "linear-gradient(180deg, #C8FFBA 0%, rgba(200, 255, 186, 0) 100%)",
    // Add more gradient colors as needed
  ];
  let uservariationprice = 0;

  if (selectedVariantPrice !== "") {
    uservariationprice = parseInt(selectedVariantPrice);
  } else {
    uservariationprice = productDetails.price;
  }

  uservariationprice = uservariationprice * (quantity > 1 ? quantity : 1);
  console.log("priceuservariationprice: ", uservariationprice);

  const Amount = Math.floor(
    uservariationprice -
      (parseInt(uservariationprice) * parseInt(productDetails.discount)) / 100
  );
  console.log("priceAmount: ", Amount);

  // const Amount = Math.floor(
  //   uservariationprice * quantity -
  //     (uservariationprice * quantity * productDetails.discount) / 100
  // ).toFixed(2);
  const formattedAmount = parseInt(Amount).toString();
  console.log("priceformattedAmount: ", formattedAmount);
  const calculatedPrice = selectedVariantPrice
    ? selectedVariantPrice -
      (selectedVariantPrice * productDetails.discount) / 100
    : productDetails?.price;
  // with outlogin
  const calculatedPriceWithoutlogin = selectedVariantPrice
    ? selectedVariantPrice
    : productDetails?.price;
  // const savedAmount = (
  //   productDetails.price * quantity -
  //   (productDetails.price * quantity * productDetails.discount) / 100
  // ).toFixed(2);
  const savedAmount = Math.floor(
    productDetails.price * quantity - Amount
  ).toFixed(2);
  const formattedSavedAmount = Number(savedAmount).toString();
  const MrpPrice = Number(savedAmount).toString();

  let suggestionTotalPrice = 0;
  comboData &&
    comboData.map((item) => {
      let priceeee = Number(item.combo_price);
      return (suggestionTotalPrice += priceeee);
    });
  console.log("suggestionTotalPrice: ", suggestionTotalPrice);

  // coupen code funtion after apply close button start

  const [totalPrice, setTotalPrice] = useState(0);
  useEffect(() => {
    setTotalPrice(Amount);
  }, [Amount]);
  // coupen code funtion after apply close button end
  // Set deliveryChargesAmount based on the value of originalPrice
  const deliveryChargesAmount = totalPrice <= 999 ? 40 : 0;

  // State for delivery charges
  const [deliveryCharges, setDeliveryCharges] = useState(0);

  // Use useEffect to update the total price when the deliveryChargesAmount changes
  useEffect(() => {
    setDeliveryCharges(deliveryChargesAmount);
  }, [deliveryChargesAmount]);
  // coupen code funtion after apply close button end

  const addToWishlist = async (item_id) => {
    const formData = new FormData();
    formData.append("user_id", storedUserId);
    formData.append("item_id", item_id);
    axios
      .post(`${BASE_URL}/customer/wish-list/add`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        console.log("response143", response);
        if (response.data.message) {
          toast.success("Added successfully");
        }
      })
      .catch((error) => {
        toast.error("Already in your wishlist");
      });
  };

  const [mainImage, setMainImage] = useState("");
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [lightboxImageIndex, setLightboxImageIndex] = useState(0);

  useEffect(() => {
    if (productDetails.image) {
      setMainImage(
        "https://admin.canineproducts.in/storage/app/public/product/" +
          productDetails.image
      );
    }
  }, [productDetails]);

  const handleThumbnailClick = (index) => {
    setMainImage(
      "https://admin.canineproducts.in/storage/app/public/product/" +
        productDetails.images[index]
    );
  };

  const handleMainImageClick = () => {
    setLightboxIsOpen(true);
    setLightboxImageIndex(productDetails.images.indexOf(mainImage));
  };

  const handleVariantChange = (e) => {
    setSelectedVariant(e.target.value);
  };
  const [homebanner, sethomebanner] = useState([]);
  const AllBanner = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/categories/banner`);
      sethomebanner(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [getreviewlist, setgetreviewlist] = useState([]);
  const AllGetreviewList = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/items/get_reviewitem/${id}`
      );
      setgetreviewlist(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  // ===================================================
  // ======================================================

  const [buttonVisibility, setButtonVisibility] = useState({});
  const handleMouseEnter = (productId) => {
    setButtonVisibility((prevVisibility) => ({
      ...prevVisibility,
      [productId]: true,
    }));
  };
  const handleMouseLeave = (productId) => {
    setButtonVisibility((prevVisibility) => ({
      ...prevVisibility,
      [productId]: false,
    }));
  };
  const handeldataId = (id) => {
    productDatatwo(id);
  };
  // suggetion
  const [suggetionbuttonVisibility, setSuggetionButtonVisibility] = useState(
    {}
  );
  const handleMouseEntersuggetion = (suggetionproductId) => {
    setSuggetionButtonVisibility((prevsuggetionVisibility) => ({
      ...prevsuggetionVisibility,
      [suggetionproductId]: true,
    }));
  };
  const handleMouseLeavesuggetion = (suggetionproductId) => {
    setSuggetionButtonVisibility((prevsuggetionVisibility) => ({
      ...prevsuggetionVisibility,
      [suggetionproductId]: false,
    }));
  };

  const suggetionhandeldataId = (suggestionproductsID) => {
    productDatasuggetion(suggestionproductsID);
  };

  const productDatasuggetion = async (selctId) => {
    axios
      .get(`${BASE_URL}/items/product_details/${selctId}`)
      .then((response) => {
        setSuggestionDetails(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const productDatatwo = async (selctId) => {
    axios
      .get(`${BASE_URL}/items/product_details/${selctId}`)
      .then((response) => {
        setProductDetails(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const shippingpage = useNavigate("");
  // const taxamound = Math.floor(Amount * 0.05);
  const handleQuantityChangebuynow = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity)) {
      setQuantity(newQuantity);
    }
  };

  const [addresslist, setAddressList] = useState([]);
  const allAddressList = async () => {
    axios
      .get(`${BASE_URL}/customer/address/list/${storedUserId}`)
      .then((response) => {
        setAddressList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressContentVisible, setAddressContentVisible] = useState(false);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const handleAddressClick = (index) => {
    setSelectedAddress(addresslist[index]);
    setAddressContentVisible(false); // Hide the address content after selecting an address
    setIsAddressSelected(true);
  };

  const toggleAddressContent = () => {
    setAddressContentVisible(!addressContentVisible);
  };

  const [stateall, setStateall] = useState([]);
  const [stateallCity, setStateallCity] = useState([]);
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [mobile, setmobile] = useState("");
  const [house_no, sethouse_no] = useState("");
  const [area, setarea] = useState("");
  const [landmark, setlandmark] = useState("");
  const [pincode, setpincode] = useState("");
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [profileData, setProfileData] = useState({});

  const [responseMessage, setResponseMessage] = useState("");
  const handleAddAddress = async (event) => {
    event.preventDefault();
    const data = {
      user_id: storedUserId,
      first_name: first_name,
      last_name: last_name,
      mobile: mobile,
      house_no: house_no,
      area: area,
      landmark: landmark,
      state: state,
      city: city,
      pincode: pincode,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/customer/address/add`,
        data
      );
      setResponseMessage(response.data.message);
      toast.success("Successfully added!");

      // Call allAddressList to update the address list
      await allAddressList();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [first_nameError, setFirst_nameError] = useState("");

  const [last_nameError, setLast_nameError] = useState("");

  const [mobileError, setMobileError] = useState("");

  const [house_noError, setHouse_noError] = useState("");

  const [areaError, setAreaError] = useState("");

  const [landmarkError, setLandmarkError] = useState("");

  const [pincodeError, setPincodeError] = useState("");

  const [stateError, setStateError] = useState("");

  const [cityError, setCityError] = useState("");

  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = () => {
    if (
      first_name.trim() === "" ||
      last_name.trim() === "" ||
      mobile.trim() === "" ||
      house_no.trim() === "" ||
      area.trim() === "" ||
      landmark.trim() === "" ||
      state.trim() === "" ||
      selectedCity.trim() === "" ||
      pincode.trim() === ""
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };

  const [selectedCity, setSelectedCity] = useState("");
  const GetdataAll = async (e) => {
    var headers = {
      Accept: "application/json",
      "Content-Data": "application/json",
      "Access-Control-Allow-Methods": "POST",
      "Access-Control-Allow-Headers": "Content-Type",
    };
    await fetch(`${BASE_URL}/auth/state`, {
      method: "GET",
      headers: headers,
    })
      .then((Response) => Response.json())
      .then((Response) => {
        setStateall(Response?.data ? Response?.data : []);
      })
      .catch((error) => {
        console.error("ERROR FOUND---->>>>" + error);
      });
  };

  const Getdatacity = (state) => {
    axios
      .post(`${BASE_URL}/auth/city?state=${state}`, {
        headers: { "Content-Data": "multipart/form-data" },
      })
      .then((response) => {
        setStateallCity(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const Subscription = (event) => {
    if (event.target.value) {
      setstate(event.target.value);

      Getdatacity(event.target.value);
    }
  };

  const removeFromCart = async (selctId) => {
    try {
      const response = await axios
        .delete(`${BASE_URL}/customer/wish-list/remove_product/${selctId}`)
        .then((response) => {
          window.location.reload(false);
        });
    } catch (error) {
      console.error("Error removing product from cart:", error);
    }
  };

  const handleDeleteAddress = (id) => {
    axios
      .delete(`${BASE_URL}/customer/address/delete/${id}`)
      .then((response) => {
        toast.success("Address deleted successfully");
        setAddressList((prevAddressList) =>
          prevAddressList.filter((item) => item.id !== id)
        );
      })
      .catch((error) => {
        console.error("Error deleting address:", error);
      });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/customer/address/update`,
        profileData // Send the updated profileData in the request body
      );
      if (response.data.status === 200) {
        setAddressList((prevAddressList) =>
          prevAddressList.filter((item) => item.id !== id)
        );
        fieldpagerefresh(); // Call fieldpagerefresh here
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [couponlist, setcouponlist] = useState([]);
  const couponlistdata = async () => {
    axios
      .get(`${BASE_URL}/coupon/list`)
      .then((response) => {
        setcouponlist(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [coupencode, setcoupenCode] = useState(false);
  const [appliedCoupon, setAppliedCoupon] = useState(false);
  const data = localStorage.getItem("disconut");
  const disscountvalue = JSON.parse(data);
  useEffect(() => {
    // Function to be called on page refresh
    const clearCoupon = () => {
      setcoupenCode(!coupencode);
      setAppliedCoupon(false); // Set appliedCoupon to false when the "X" button is clicked
      setTotalPrice(Amount);
      localStorage.removeItem("disconut"); // Optionally, you can remove the discount value from localStorage here
    };

    // Attach the event listener when the component mounts
    window.addEventListener("beforeunload", clearCoupon);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", clearCoupon);
    };
  }, []);
  const coupendisscount = (dis) => {
    setcoupenCode(!coupencode);
    localStorage.setItem("disconut", JSON.stringify(dis));
    setAppliedCoupon(true); // Set appliedCoupon to true when the button is clicked
    // setTotalPrice(
    //   parseInt(originalPrice + originalPrice * 0.05 - disscountvalue)
    // );
    const discountAmount = dis?.discount || 0;
    let newTotalPrice = Amount - discountAmount;

    // Update totalPrice only if the newTotalPrice is a valid number
    if (!isNaN(newTotalPrice)) {
      setTotalPrice(newTotalPrice);
    } else {
      console.error("Invalid totalPrice calculation. Check your values.");
    }
    console.log("disccount?????", dis);
  };
  const clearCoupon = () => {
    setcoupenCode(!coupencode);
    setAppliedCoupon(false); // Set appliedCoupon to false when the "X" button is clicked
    setTotalPrice(Amount);
    localStorage.removeItem("disconut"); // Optionally, you can remove the discount value from localStorage here
  };
  const [selectedInput, setSelectedInput] = useState("");
  function formatAddress(selectedAddress) {
    return `${selectedAddress.first_name} ${selectedAddress.last_name}, ${selectedAddress.house_no} ${selectedAddress.area} ${selectedAddress.landmark}, ${selectedAddress.city}, ${selectedAddress.state} ${selectedAddress.pincode}, Mobile: ${selectedAddress.mobile}`;
  }
  // ...

  // Use the formatAddress function to get the selected address as a single string
  const deliveryAddress = selectedAddress
    ? formatAddress(selectedAddress)
    : "No address selected";
  // Buy now checkout code
  const handleSendRequest = async () => {
    // const cartData = sendcartdata.map((item) => ({
    //   product_id: item.item_id,
    //   variation: item.variant,
    //   price: item.price,
    //   quantity: item.quantity,
    //   tax_amount: taxamound,
    //   discount_on_item: disscountvalue?.discount || "",
    // }));
    const cartData = {
      product_id: productDetails.id,
      variation: selectedVariant,
      price:
        calculatedPrice === 0
          ? parseInt(productDetails?.price) * quantity
          : parseInt(calculatedPrice),
      quantity: quantity,
      tax_amount: 0,
      discount_on_item: disscountvalue?.discount || "",
      total_quantity:
        selectedVariantStock.length > 0
          ? selectedVariantStock
          : productDetails?.stock,
      return_order: productDetails?.returnable || "yes",
      // returnable: "yes",
    };
    // Calculate the order_amount
    const orderAmount = parseInt(totalPrice);

    const requestData = {
      user_id: storedUserId,
      delivery_charge: deliveryCharges,
      coupon_discount_amount: disscountvalue?.discount || "",
      coupon_discount_title: disscountvalue?.title || "",
      payment_status: "paid",
      order_status: "pending",
      total_tax_amount: 0,
      payment_method: selectedInput ? "offline" : "online",
      transaction_reference: selectedInput ? "" : "sadgash23asds",
      delivery_address_id: 2,
      coupon_code: disscountvalue?.code || "",
      order_type: "delivery",
      checked: selectedInput,
      store_id: vendorIDstore || 1,
      zone_id: 2,
      delivered_status: "undelivered",
      delivery_address: deliveryAddress,
      item_campaign_id: "",
      order_amount: orderAmount,
      //    {
      //   (parseInt(Amount) * 0.05) + parseInt(Amount) - (
      //     (disscountvalue?.discount ?? 0)
      //   )
      // },
      // {`${parseInt( Amount * 0.05 + Amount - disscountvalue?.discount + taxamound)}`},
      // parseInt(Amount * 0.05 + Amount - disscountvalue?.discount) ||
      // Amount * 0.05 + Amount,
      cart: [cartData],
    };
    fetch(`${BASE_URL}/customer/order/place`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        console.log("responseData???>>>>", responseData);
        shippingpage("/shipping/" + responseData.data.order_id);
        console.log("order_id", responseData);
      })
      .catch((error) => {
        console.error("Error sending request:", error);
      });
    const modal = document.querySelector(".modal");
    if (modal) {
      modal.classList.remove("show");
      modal.style.display = "none";
      document.body.classList.remove("modal-open");
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    }
  };

  const handleRadioChange = (event) => {
    setSelectedInput(event.target.checked);
  };
  const [reviewlist, setreviewlist] = useState([]);
  const allReview = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/customer/order/list?id=${storedUserId}`
      );
      const data = await response.json();
      const latestPosts = data.data.slice(0, 3);
      setreviewlist(latestPosts);
    } catch (error) {
      console.log(error);
    }
  };
  const quickViewClear = () => {
    setSelectedVariantPrice(null);
    setSelectedVariant(null);
    setSelectedVariantStock(null);
    setQuantity(1);
  };
  const suggestionquickViewClear = () => {
    setSelectedVariantPrice(null);
    setSelectedVariant(null);
    setSelectedVariantStock(null);
    setQuantity(1);
  };
  const handleResetClick = () => {
    setfirst_name(null);
    setlast_name(null);
    setmobile(null);
    sethouse_no(null);
    setarea(null);
    setlandmark(null);
    setpincode(null);
    setstate(null);
    setcity(null);
    setFirst_nameError(null);
    setLast_nameError(null);
    setMobileError(null);
    setHouse_noError(null);
    setAreaError(null);
    setLandmarkError(null);
    setPincodeError(null);
    setStateError(null);
    setCityError(null);
    setIsFormValid(null);
    setSelectedCity(null);
    setcoupenCode(null);
    setAppliedCoupon(null);
    setSelectedInput(null);
    setAddressContentVisible(null);
    setSelectedAddress(null);
    setQuantity(1);
  };

  // loadRazorpayScript
  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    try {
      await loadRazorpayScript();

      const options = {
        key: "rzp_test_yXpKwsLWjkzvBJ", // Replace with your actual key
        amount: 10000, // Amount in paise (100 INR)
        currency: "INR",
        name: "HEllo world",
        description: "Test Payment",
        image: "https://your_logo_url.png",
        // order_id: response.id, // Order ID obtained from Razorpay
        handler: (response) => {
          setPaymentId(response.razorpay_payment_id);
          // Handle the success callback
          window.location.href = "/shipping";
          console.log("Payment Successful:", response);
        },

        prefill: {
          email: "test@example.com",
          contact: "1234567890",
        },
        notes: {
          address: "1234, Demo Address",
        },
        theme: {
          color: "#F37254",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Razorpay Load Error:", error);
    }
  };
  const [showData, setShowData] = useState(false);

  const toggleData = (e) => {
    e.preventDefault();
    setShowData(!showData);
  };

  const renderProducthead = (name) => {
    const maxCharacters = 15;
    if (name?.length <= maxCharacters) {
      return <h6>{name}</h6>;
    }
    const truncatedDescription = name?.slice(0, maxCharacters);
    return (
      <>
        <h6>{truncatedDescription}..</h6>
      </>
    );
  };

  const renderProductDescription = (description) => {
    const maxCharacters = 35;

    if (description?.length <= maxCharacters) {
      return <p>{description}</p>;
    }

    const truncatedDescription = description?.slice(0, maxCharacters);

    return (
      <>
        <p>{truncatedDescription}.......</p>
      </>
    );
  };

  const [showContent, setShowContent] = useState(true);

  const handleCancelIconClick = () => {
    setShowContent(!showContent);
  };
  // const handleCancelIconClick = () => {
  //   setShowContent(false);
  // };
  // const handleCancelIconClickTrue = () => {
  //   setShowContent(true);
  // };

  return (
    <>
      <Toaster />

      <Newheader />
     <Breadcrumbs/>

      <div className="home-section">
        {homebanner
          ? homebanner.map(
              (item, index) =>
                item.type === "common" && (
                  <Link to={item.default_link}>
                    <img
                      className="partner-img"
                      src={
                        "https://admin.canineproducts.in//storage/app/" +
                        item.image
                      }
                    />
                  </Link>
                )
            )
          : null}
      </div>

      <section className="section-padding">
        <Container>
          <Row>
            <Col lg={6} sm={6}>
              <>
                <div>
                  <div className="product-item">
                    <img
                      src={mainImage}
                      alt="Product Image"
                      onClick={handleMainImageClick}
                    />
                  </div>
                  <div className="needplace">
                    <Row>
                      {productDetails?.images &&
                      productDetails?.images.length > 0 ? (
                        productDetails.images.map((item, index) => (
                          <Col
                            lg={2}
                            sm={3}
                            xs={3}
                            className="mb-3"
                            key={index}
                          >
                            <div
                              className="product-item-inner"
                              onClick={() => handleThumbnailClick(index)}
                            >
                              <img
                                src={
                                  "https://admin.canineproducts.in/storage/app/public/product/" +
                                  item
                                }
                                alt={`Image ${index}`}
                              />
                            </div>
                          </Col>
                        ))
                      ) : (
                        <p className="emptyMSG">No Related Image.</p>
                      )}
                    </Row>
                  </div>
                </div>

                {lightboxIsOpen && (
                  <Lightbox
                    images={productDetails.images.map((item) => ({
                      url:
                        "https://admin.canineproducts.in/storage/app/public/product/" +
                        item,
                      title: productDetails.name,
                    }))}
                    currentIndex={lightboxImageIndex}
                    onClose={() => setLightboxIsOpen(false)}
                  />
                )}
              </>
            </Col>

            <Col lg={6} sm={6}>
              <div className="productDetail-content">
                <Row>
                  <Col lg={12} sm={12} xs={12}>
                    <p>
                      {productDetails.veg == 0 ? (
                        <span>
                          <span className="non-vegetarian">●</span>
                        </span>
                      ) : (
                        <span>
                          <span className="vegetarian">●</span>
                        </span>
                      )}

                      <RWebShare
                        data={{
                          text: `Check out this amazing product: ${productDetails.name}`,
                          url: window.location.href,
                          title: productDetails.name,
                        }}
                        onClick={() =>
                          console.log("Product shared successfully!")
                        }
                      >
                        <div className="share-btn">
                          <i class="fa fa-share-alt" />
                        </div>
                      </RWebShare>
                    </p>
                  </Col>
                  <Col lg={12} sm={12} xs={12}>
                    <h4>{productDetails.name}</h4>
                  </Col>
                </Row>
                <p>
                  By <span>{productDetails?.brand_id}</span>
                </p>

                <Wrapper>
                  <div className="icon-style">
                    {ratingStar}
                    <p>({productDetails.rating_count} customer reviews)</p>
                  </div>
                </Wrapper>

                <div className="needplaceProduct">
               
                    {productDetails?.variations?.length > 0 && (
                     
                        <div>
                          <div>
                            <div className="tab-container">
                              <h6>Variations</h6>
                              <Row>
                                {productDetails?.variations &&
                                  productDetails?.variations.length > 0 &&
                                  productDetails?.variations.map(
                                    (item, index) => (
                                      <Col
                                        lg={3}
                                        xs={3}
                                        key={index}
                                        className="p-0"
                                      >
                                        {/* <div
                                        className={`tab-variations ${
                                          selectedVariant === item.type
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          setSelectedVariant(item.type);
                                          setSelectedVariantPrice(item.price); // Store the price in state
                                        }}
                                      >
                                        {item.type}
                                      </div> */}
                                        {item.stock !== 0 ? (
                                          <div
                                            className={`tab-variations ${
                                              selectedVariant === item.type
                                                ? "active"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              setSelectedVariant(item.type);
                                              setSelectedVariantPrice(
                                                item.price
                                              ); // Store the price in state
                                              setSelectedVariantStock(
                                                item.stock
                                              );
                                            }}
                                          >
                                            {item.type}
                                          </div>
                                        ) : (
                                          <div
                                            className="tab-variations disabledvariation"
                                            title="Stock unavailable"
                                          >
                                            {/* <span className="blurred-text"> */}
                                            {item.type}
                                            {/* </span> */}
                                          </div>
                                        )}
                                      </Col>
                                    )
                                  )}
                              </Row>
                            </div>
                          </div>
                        </div>
                   
                    )}
                
                      <div className="quantity-btn">
                        <button onClick={handleDecrementone}>
                          <i className="fa fa-minus" />
                        </button>
                        <form>
                          <div className="form-group">
                            <input
                              type="tel"
                              className="form-control"
                              placeholder="Quantity"
                              value={quantity}
                              onChange={handleQuantityChange}
                              autoComplete="new-number"
                            />
                          </div>
                        </form>
                        <button onClick={handleIncrementone}>
                          <i className="fa fa-plus" />
                        </button>
                      </div>
                  
                </div>
                <div className="needplaceProduct">
                  <div className="product-deatils-price">
                    {uservariationprice && formattedAmount >= 0 ? (
                      <Row>
                        <Col lg={3} sm={3} xs={4}>
                          <p>{`₹${uservariationprice}`}</p>
                        </Col>
                        <Col lg={4} sm={4} xs={4}>
                          <h5>{`₹${
                            isNaN(formattedAmount) ? 0 : formattedAmount
                          }`}</h5>
                        </Col>
                        {/* {formattedSavedAmount > 0 && ( */}
                        <Col lg={5} sm={5} xs={4}>
                          {formattedSavedAmount > 0 ? (
                            <h6>You save ₹{formattedSavedAmount}</h6>
                          ) : (
                            <h6>No savings</h6>
                          )}
                        </Col>
                        {/* )} */}
                      </Row>
                    ) : (
                      <Row>
                        <Col lg={4} sm={4} xs={4}>
                          <h5>{`₹${isNaN(MrpPrice) ? 0 : MrpPrice}`}</h5>
                        </Col>
                      </Row>
                    )}
                  </div>
                  <Row>
                    <Col lg={5} sm={5} xs={12}>
                      <p>(inclusive of all taxes)</p>
                    </Col>
                  </Row>
                </div>
                <h5>About Us</h5>
                {productDetails ? (
                  <Table responsive>
                    <tbody>
                      <tr>
                        <th>Brand</th>
                        <td>{productDetails?.brand_id}</td>
                      </tr>
                      <tr>
                        <th>Age Range</th>
                        <td>{productDetails?.lifeStage_id}</td>
                      </tr>
                      <tr>
                        <th>Target Species</th>
                        <td>{productDetails?.Petsbreeds_id}</td>
                      </tr>
                      {/* <tr>
                          <th>Item From</th>
                          <td>Pellet</td>
                        </tr> */}
                    </tbody>
                  </Table>
                ) : (
                  <p>No data available for this product.</p>
                )}
              </div>
            </Col>
          </Row>

          {productDetails.stock && productDetails.stock.length !== 0 ? (
            <div className="productBTNaddcard">
              {customerLoginId === null ? (
                <Button>
                  <Link
                    to={"/cart"}
                    onClick={() => {
                      dispatch({
                        type: "ADD_TO_CART",
                        payload: {
                          item_id: productDetails.id,
                          variant: selectedVariant,
                          price:
                            calculatedPrice === 0
                              ? parseInt(productDetails?.price) * quantity
                              : parseInt(calculatedPrice),
                          quantity: quantity,
                          item_name: productDetails.name,
                          image: productDetails.image,
                          total_quantity:
                            selectedVariantStock.length > 0
                              ? selectedVariantStock
                              : productDetails?.stock,
                          return_order: productDetails?.returnable || "yes",
                          orderamountwithquantity:
                            calculatedPrice === 0
                              ? parseInt(formattedAmount) * quantity
                              : parseInt(calculatedPrice) * quantity,
                        },
                      });
                    }}
                  >
                    <i className="fa fa-shopping-bag" /> Add to cart
                  </Link>
                  <p>{addToCartStatus}</p>
                </Button>
              ) : (
                <Button onClick={() => handleAddToCart()}>
                  {/* <Link to={`/add-cart/${id}`} onClick={handleAddToCart}> */}
                  <i className="fa fa-shopping-bag" /> Add to cart
                  {/* </Link> */}
                  <p>{addToCartStatus}</p>
                </Button>
              )}
            </div>
          ) : (
            <div className="sold-out-btn mt-3">
              <Link>Sold Out</Link>
              <br />
              <Button data-toggle="modal" data-target="#soldoutModel">
                Notify Me When Available
              </Button>
            </div>
          )}
          {/* suggetion product start */}
          <section className="section-padding food">
            <Container>
              <div
                className="text-left"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h1 className="main-head">Frequently bought together</h1>
                <i
                  className={
                    showContent ? "fa fa-chevron-down" : "fa fa-chevron-up"
                  }
                  onClick={handleCancelIconClick}
                />
                {/* <i
                  className="fa fa-chevron-down"
                  onClick={handleCancelIconClick}
                />
                <i
                  className="fa fa-chevron-up"
                  onClick={handleCancelIconClickTrue}
                /> */}
              </div>
              {showContent && (
                <div className="needplace">
                  <Row className="justify-content-center">
                    {comboData &&
                      comboData.map((item, index) => (
                        <>
                          <Col lg={3} sm={6} xs={12} className="mb-4">
                            <div
                              className="food-product"
                              // onMouseEnter={() =>
                              //   handleMouseEntersuggetion(item.id)
                              // }
                              // onMouseLeave={() =>
                              //   handleMouseLeavesuggetion(item.id)
                              // }
                              key={item.id}
                              style={{
                                background:
                                  gradientColors[index % gradientColors.length],
                              }}
                            >
                              {/* <i
                                class="fa fa-heart-o"
                                onClick={(id) => addToWishlist(item.id)}
                              /> */}
                              <Link to={`/product/${item.id}`}>
                                <div className="text-center">
                                  <img
                                    src={
                                      "https://admin.canineproducts.in///storage/app/public/product/" +
                                      item.image
                                    }
                                  />
                                </div>
                                <div>
                                  <h6>{renderProducthead(item.name)}</h6>
                                  <p>
                                    {renderProductDescription(item.description)}
                                  </p>
                                </div>
                                <div className="product-bag">
                                  <Row>
                                    <Col lg={6} sm={6} xs={6}>
                                      <p>{item.price}</p>
                                    </Col>
                                    {/* <Col lg={6} sm={6} xs={6}>
                                      <h5>Save {parseFloat(item.discount)}%</h5>
                                    </Col> */}
                                  </Row>
                                  <Row>
                                    <Col
                                      lg={6}
                                      sm={6}
                                      xs={6}
                                      className="align-self-center"
                                    >
                                      <h4>{item.combo_price}</h4>
                                    </Col>
                                  </Row>
                                </div>
                              </Link>
                            </div>
                          </Col>
                          {index < comboData.length - 1 && (
                            <Col
                              lg={1}
                              sm={2}
                              xs={2}
                              className="mb-4 d-flex align-items-center justify-content-center"
                              key={`plus-${item.id}`}
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </Col>
                          )}
                        </>
                      ))}
                  </Row>
                  <Row>
                    <Col lg={8}>
                      {comboData?.length > 0 ? (
                        <Row>
                          <Col lg={2} xs={3}>
                            <div className="frequ">
                              <h6>1 Item</h6>
                              {comboData[0] && (
                                <h2>{comboData[0]?.combo_price}</h2>
                              )}
                            </div>
                          </Col>

                          {comboData[1] && (
                            <>
                              <Col
                                lg={1}
                                xs={1}
                                className="d-flex align-items-center justify-content-left"
                              >
                                <h4>+</h4>
                              </Col>
                              <Col lg={2} xs={3}>
                                <div className="frequ">
                                  <h6>1 Add-ons</h6>
                                  <h2>
                                    {comboData[1]
                                      ? comboData[1]?.combo_price
                                      : 0}
                                  </h2>
                                </div>
                              </Col>
                            </>
                          )}

                          {comboData[2] && (
                            <>
                              <Col
                                xs={1}
                                className="d-flex align-items-center justify-content-left"
                              >
                                <h4>+</h4>
                              </Col>
                              <Col lg={2} xs={2}>
                                <div className="frequ">
                                  <h6>2 Add-ons</h6>
                                  <h2>
                                    {comboData[2]
                                      ? comboData[2]?.combo_price
                                      : 0}
                                  </h2>
                                </div>
                              </Col>
                            </>
                          )}
                          <Col
                            lg={1}
                            xs={1}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <h4>=</h4>
                          </Col>
                          <Col lg={3} xs={3}>
                            <div className="frequ">
                              <h6>Total</h6>
                              <h2>₹{suggestionTotalPrice}</h2>
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                    <Col lg={4} className="align-self-center">
                      <div className="frequ-btn">
                        {customerLoginId === null ? (
                          <Button>
                            <Link
                              to={"/cart"}
                              onClick={() => {
                                for (let i = 0; i < comboData.length; i++) {
                                  const element = comboData[i];
                                  dispatch({
                                    type: "ADD_TO_CART",
                                    payload: {
                                      item_name: element?.name,
                                      variant: element?.combo_variation,
                                      image: element?.image,
                                      quantity: 1,
                                      total_quantity: element?.stock,
                                      return_order:
                                        element?.returnable || "yes",
                                      price: element?.combo_price,
                                      item_id: element?.id,
                                      type: "combo",
                                      min_order: "",
                                      seller_id: "",
                                      orderamountwithquantity:
                                        element?.combo_price,
                                    },
                                  });
                                }
                              }}
                            >
                              <i className="fa fa-shopping-bag" />{" "}
                              {`ADD ${comboData?.length} ITEMS TO CART`}
                            </Link>
                          </Button>
                        ) : (
                          <button onClick={() => handleComboAddToCart()}>
                            <i class="fa fa-shopping-cart" />{" "}
                            {`ADD ${comboData?.length} ITEMS TO CART`}
                          </button>
                        )}
                        <p>{addToCartStatus}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </Container>
          </section>
          {/* suggetion product end */}
          <div>
            <h1 className="main-head mt-4">Product details</h1>
            <p>{productDetails.description}</p>
          </div>
          <hr />
          <div className="Product-Review">
            <h1 className="main-head mt-4">Product Review</h1>

            {getreviewlist && getreviewlist.length > 1 ? (
              getreviewlist.map(
                (order, index) =>
                  index === 0 && (
                    <div key={order.id}>
                      <div className="linereview">
                        <p>{order.comment}</p>
                        <div className="row">
                          <div className="col-sm-3 col">
                            <Wrapper>
                              <div className="icon-style">
                                {Array.from({
                                  length: order.rating,
                                }).map((_, index) => (
                                  <i className="fa-solid fa-star" key={index} />
                                ))}
                              </div>
                            </Wrapper>
                          </div>
                          <div className="col-sm-5 col">
                            {order.user_id && order.user_id.length > 0 && (
                              <div className="Product-img">
                                <img
                                  src={
                                    "https://admin.canineproducts.in//storage/app/public/profile/" +
                                    order.user_id[0].image
                                  }
                                  alt={order.user_id[0].f_name}
                                />
                                <span>
                                  {order.user_id[0].f_name}{" "}
                                  {order.user_id[0].l_name}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )
            ) : (
              <p>No Review data</p>
            )}

            <div>
              {showData ? (
                <>
                  {getreviewlist.map((order) => (
                    <div key={order.id}>
                      <div className="linereview">
                        <p>{order.comment}</p>
                        <div className="row">
                          <div className="col-sm-3 col">
                            <Wrapper>
                              <div className="icon-style">
                                {Array.from({
                                  length: order.rating,
                                }).map((_, index) => (
                                  <i className="fa-solid fa-star" key={index} />
                                ))}
                              </div>
                            </Wrapper>
                          </div>
                          <div className="col-sm-5 col">
                            {order.user_id && order.user_id.length > 0 && (
                              <div className="Product-img">
                                <img
                                  src={
                                    "https://admin.canineproducts.in//storage/app/public/profile/" +
                                    order.user_id[0].image
                                  }
                                  alt={order.user_id[0].f_name}
                                />
                                <span>
                                  {order.user_id[0].f_name}{" "}
                                  {order.user_id[0].l_name}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <a href="#" onClick={toggleData}>
                    Read less
                  </a>
                </>
              ) : (
                <a href="#" onClick={toggleData}>
                  Read more
                </a>
              )}
            </div>
          </div>
        </Container>
      </section>

      <section className="section-padding food">
        <Container>
          <div className="text-left">
            <h1 className="main-head">Related products</h1>
          </div>
          <div className="needplace">
            <Row>
              {allrelatedproduct &&
                allrelatedproduct.map((item, index) => (
                  <Col lg={3} sm={6} xs={6} className="mb-4">
                    <div
                      className="food-product"
                      onMouseEnter={() => handleMouseEnter(item.id)}
                      onMouseLeave={() => handleMouseLeave(item.id)}
                      key={item.id}
                      style={{
                        background:
                          gradientColors[index % gradientColors.length],
                      }}
                    >
                      <i
                        class="fa fa-heart-o"
                        onClick={(id) => addToWishlist(item.id)}
                      />
                      <Link to={`/product/${item.id}`}>
                        <div className="text-center">
                          <img
                            src={
                              "https://admin.canineproducts.in///storage/app/public/product/" +
                              item.image
                            }
                          />
                        </div>
                        <div>
                          <h6>{renderProducthead(item.name)}</h6>
                          <p>{renderProductDescription(item.description)}</p>
                        </div>
                        <div className="product-bag">
                          <Row>
                            <Col lg={6} sm={6} xs={6}>
                              <p>{item.price}</p>
                            </Col>
                            <Col lg={6} sm={6} xs={6}>
                              <h5>Save {parseFloat(item.discount)}%</h5>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              lg={6}
                              sm={6}
                              xs={6}
                              className="align-self-center"
                            >
                              <h4>{`₹${Math.floor(
                                item.price - (item.price * item.discount) / 100
                              )}`}</h4>
                            </Col>
                            {/* <Col lg={6} sm={6} xs={6}>
                              <Link
                                to={`/add-cart/${item.id}`}
                                onClick={handleAddToCart}
                              >
                                <img src={bag} />
                              </Link>
                            </Col> */}
                          </Row>
                        </div>
                      </Link>

                      {/* {buttonVisibility[item.id] && (
                        <Fade top> */}
                      <div className="button-container">
                        <button
                          data-toggle="modal"
                          data-target=".bd-example-modal-lg"
                          onClick={(e) => handeldataId(item.id)}
                        >
                          Quick View
                        </button>
                        <button
                          data-toggle="modal"
                          data-target=".buynow"
                          onClick={(e) => handeldataId(item.id)}
                        >
                          Buy Now
                        </button>
                      </div>
                      {/* </Fade>
                      )} */}
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </Container>
      </section>
      <Footer />

      {/* Modal */}
      <div
        className="modal fade"
        id="soldoutModel"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h4>{productDetails.name}</h4>
              <p>{productDetails.description}</p>
              <Form onSubmit={handleNotifymeSubmit}>
                <Form.Group controlId="formVariations" className="mb-3">
                  <Form.Label>Variations</Form.Label>
                  <Form.Control
                    as="select"
                    value={variation}
                    onChange={(e) => {
                      setVariation(e.target.value);
                      setVariationError(""); // Clear previous error when the value changes
                    }}
                    required
                    isInvalid={!!variationError}
                  >
                    <option value="" disabled>
                      Choose an option...
                    </option>

                    {productDetails?.variations &&
                      productDetails?.variations.map((item, index) => (
                        <option key={index}>{item.type}</option>
                      ))}
                  </Form.Control>
                  {variationError && (
                    <div className="error-message">{variationError}</div>
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email ID"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setIsEmailValid(isEmailFormatValid(e.target.value));
                    }}
                    isInvalid={!isEmailValid}
                  />
                  {!isEmailValid && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="custom-form-control-feedback"
                    >
                      {/[A-Z]/.test(email) && !email.includes("@")
                        ? "Email should not contain capital letters and must include '@'."
                        : "Please enter a valid email address."}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Button
                  variant="primary mt-3"
                  type="submit"
                  data-dismiss="modal"
                >
                  Notify Me When Available
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {/* quick Product details Modal */}
      <div
        className="modal fade bd-example-modal-lg"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <i
                class="quickarea fa fa-times"
                data-dismiss="modal"
                onClick={quickViewClear}
              />
              <section className="section-padding">
                <Container>
                  <Row>
                    <Col lg={6} sm={6}>
                      <>
                        <div>
                          <div className="product-item quickviewimg">
                            <img
                              src={mainImage}
                              alt="Product Image"
                              // onClick={handleMainImageClick}
                            />
                          </div>
                          <div className="needplace">
                            <Row>
                              {productDetails?.images &&
                              productDetails?.images.length > 0 ? (
                                productDetails.images.map((item, index) => (
                                  <Col
                                    lg={3}
                                    sm={3}
                                    xs={3}
                                    className="mb-3"
                                    key={index}
                                  >
                                    <div
                                      className="product-item-inner"
                                      onClick={() =>
                                        handleThumbnailClick(index)
                                      }
                                    >
                                      <img
                                        src={
                                          "https://admin.canineproducts.in/storage/app/public/product/" +
                                          item
                                        }
                                        alt={`Image ${index}`}
                                      />
                                    </div>
                                  </Col>
                                ))
                              ) : (
                                <p className="emptyMSG">No Related Image.</p>
                              )}
                            </Row>
                          </div>
                        </div>
                        {lightboxIsOpen && (
                          <Lightbox
                            mainSrc={
                              "https://admin.canineproducts.in/storage/app/public/product/" +
                              productDetails.images[lightboxImageIndex]
                            }
                            nextSrc={
                              "https://admin.canineproducts.in/storage/app/public/product/" +
                              productDetails.images[
                                (lightboxImageIndex + 1) %
                                  productDetails.images.length
                              ]
                            }
                            prevSrc={
                              "https://admin.canineproducts.in/storage/app/public/product/" +
                              productDetails.images[
                                (lightboxImageIndex +
                                  productDetails.images.length -
                                  1) %
                                  productDetails.images.length
                              ]
                            }
                            onCloseRequest={() => setLightboxIsOpen(false)}
                            onMovePrevRequest={() =>
                              setLightboxImageIndex(
                                (lightboxImageIndex +
                                  productDetails.images.length -
                                  1) %
                                  productDetails.images.length
                              )
                            }
                            onMoveNextRequest={() =>
                              setLightboxImageIndex(
                                (lightboxImageIndex + 1) %
                                  productDetails.images.length
                              )
                            }
                          />
                        )}
                      </>
                    </Col>
                    <Col lg={6} sm={6}>
                      <div className="productDetail-content">
                        <Row>
                          <Col lg={9} sm={9} xs={9}>
                            <h4>{productDetails.name}</h4>
                          </Col>
                          <Col lg={3} sm={3} xs={3}>
                            <p>
                              {productDetails.veg == 0 ? (
                                <span>
                                  <span className="non-vegetarian">●</span>
                                </span>
                              ) : (
                                <span>
                                  <span className="vegetarian">●</span>
                                </span>
                              )}
                            </p>
                          </Col>
                        </Row>
                        <p>
                          By <span>{productDetails.store_name}</span>
                        </p>
                        <Wrapper>
                          <div className="icon-style">
                            {ratingStar}
                            <p>
                              ({productDetails.rating_count} customer reviews)
                            </p>
                          </div>
                        </Wrapper>

                        <div className="needplaceProduct">
                          <Row>
                            <Col sm={6} xs={6}>
                              <div>
                                <div>
                                  <div className="tab-container">
                                    <h6>Variations</h6>
                                    <Row>
                                      {productDetails?.variations &&
                                        productDetails?.variations.length > 0 &&
                                        productDetails?.variations.map(
                                          (item, index) => (
                                            <Col
                                              lg={5}
                                              key={index}
                                              className="p-0"
                                            >
                                              {item.stock !== 0 ? (
                                                <div
                                                  className={`tab-variations ${
                                                    selectedVariant ===
                                                    item.type
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  onClick={() => {
                                                    setSelectedVariant(
                                                      item.type
                                                    );
                                                    setSelectedVariantPrice(
                                                      item.price
                                                    ); // Store the price in state
                                                  }}
                                                >
                                                  {item.type}
                                                </div>
                                              ) : (
                                                <div
                                                  className="tab-variations disabledvariation"
                                                  title="Stock unavailable"
                                                >
                                                  {/* <span className="blurred-text"> */}
                                                  {item.type}
                                                  {/* </span> */}
                                                </div>
                                              )}
                                            </Col>
                                          )
                                        )}
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col sm={6} xs={6}>
                              <div className="quantity-btn quickbtn">
                                <button onClick={handleDecrementone}>
                                  <i className="fa fa-minus" />
                                </button>
                                <form>
                                  <div className="form-group">
                                    <input
                                      type="tel"
                                      className="form-control"
                                      placeholder="Quantity"
                                      value={quantity}
                                      onChange={handleQuantityChange}
                                      autoComplete="new-number"
                                    />
                                  </div>
                                </form>
                                <button onClick={handleIncrementone}>
                                  <i className="fa fa-plus" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="needplaceProduct">
                          <div className="product-deatils-price">
                            {uservariationprice && formattedAmount >= 0 ? (
                              <Row>
                                <Col lg={3} sm={3} xs={3}>
                                  <p>{`₹${uservariationprice}`}</p>
                                </Col>
                                <Col lg={4} sm={4} xs={3}>
                                  <h5>{`₹${
                                    isNaN(formattedAmount) ? 0 : formattedAmount
                                  }`}</h5>
                                </Col>
                                {/* {formattedSavedAmount > 0 && ( */}
                                <Col lg={5} sm={5} xs={3}>
                                  {formattedSavedAmount > 0 ? (
                                    <h6>You save ₹{formattedSavedAmount}</h6>
                                  ) : (
                                    <h6>No savings</h6>
                                  )}
                                </Col>
                                {/* )} */}
                              </Row>
                            ) : (
                              <Row>
                                <Col lg={4} sm={4} xs={3}>
                                  <h5>{`₹${
                                    isNaN(MrpPrice) ? 0 : MrpPrice
                                  }`}</h5>
                                </Col>
                              </Row>
                            )}
                          </div>
                          <Row>
                            <Col lg={5} sm={5} xs={4}>
                              <p>(inclusive of all taxes)</p>
                            </Col>
                          </Row>
                        </div>
                        <h5>About Us</h5>
                        {productDetails ? (
                          <Table responsive>
                            <tbody>
                              <tr>
                                <th>Brand</th>
                                <td>{productDetails?.brand_id}</td>
                              </tr>
                              <tr>
                                <th>Age Range</th>
                                <td>{productDetails?.lifeStage_id}</td>
                              </tr>
                              <tr>
                                <th>Target Species</th>
                                <td>{productDetails?.Petsbreeds_id}</td>
                              </tr>
                            </tbody>
                          </Table>
                        ) : (
                          <p>No data available for this product.</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {productDetails?.stock &&
                  productDetails?.stock?.length !== 0 ? (
                    <div className="productBTNaddcard">
                      {customerLoginId === null ? (
                        <Button data-dismiss="modal">
                          {/* <Button> */}
                          <Link
                            onClick={() => {
                              const filterData = cart.filter((el) => {
                                console.log("elll: ", el);
                                return el.item_id === productDetails.id;
                              });
                              if (filterData?.length > 0) {
                                toast.error("Already in added");
                              } else {
                                dispatch({
                                  type: "ADD_TO_CART",
                                  payload: {
                                    item_id: productDetails.id,
                                    variant: selectedVariant,
                                    price:
                                      calculatedPrice === 0
                                        ? parseInt(productDetails?.price) *
                                          quantity
                                        : parseInt(calculatedPrice),
                                    quantity: quantity,
                                    item_name: productDetails.name,
                                    image: productDetails.image,
                                    orderamountwithquantity: formattedAmount,
                                  },
                                });
                              }
                            }}
                          >
                            <i className="fa fa-shopping-bag" /> Add to cart
                          </Link>
                          <p>{addToCartStatus}</p>
                        </Button>
                      ) : (
                        <Button>
                          <Link
                            to={`/cart/${productDetails.id}`}
                            onClick={handleAddToCart}
                          >
                            <i className="fa fa-shopping-bag" /> Add to cart
                          </Link>
                          <p>{addToCartStatus}</p>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="sold-out-btn mt-3">
                      <Link>Sold Out</Link>
                      <br />
                      <Button data-toggle="modal" data-target="#soldoutModel">
                        Notify Me When Available
                      </Button>
                    </div>
                  )}
                </Container>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* // ============================== */}
      {/* suggestion quick view Product details Modal */}
      <div
        className="modal fade bd-example-modal-lgsuggestion"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <i
                class="quickarea fa fa-times"
                data-dismiss="modal"
                onClick={suggestionquickViewClear}
              />
              <section className="section-padding">
                <Container>
                  <Row>
                    <Col lg={6} sm={6}>
                      <>
                        <div>
                          <div className="product-item quickviewimg">
                            <img
                              src={mainImage}
                              alt="Product Image"
                              onClick={handleMainImageClick}
                            />
                          </div>
                          <div className="needplace">
                            <Row>
                              {suggestionDetails?.images &&
                              suggestionDetails?.images.length > 0 ? (
                                suggestionDetails?.images.map((item, index) => (
                                  <Col
                                    lg={3}
                                    sm={3}
                                    xs={3}
                                    className="mb-3"
                                    key={index}
                                  >
                                    <div
                                      className="product-item-inner"
                                      onClick={() =>
                                        handleThumbnailClick(index)
                                      }
                                    >
                                      <img
                                        src={
                                          "https://admin.canineproducts.in/storage/app/public/product/" +
                                          item
                                        }
                                        alt={`Image ${index}`}
                                      />
                                    </div>
                                  </Col>
                                ))
                              ) : (
                                <p className="emptyMSG">No Related Image.</p>
                              )}
                            </Row>
                          </div>
                        </div>
                        {lightboxIsOpen && (
                          <Lightbox
                            mainSrc={
                              "https://admin.canineproducts.in/storage/app/public/product/" +
                              suggestionDetails?.images[lightboxImageIndex]
                            }
                            nextSrc={
                              "https://admin.canineproducts.in/storage/app/public/product/" +
                              suggestionDetails?.images[
                                (lightboxImageIndex + 1) %
                                  suggestionDetails?.images.length
                              ]
                            }
                            prevSrc={
                              "https://admin.canineproducts.in/storage/app/public/product/" +
                              suggestionDetails?.images[
                                (lightboxImageIndex +
                                  suggestionDetails?.images.length -
                                  1) %
                                  suggestionDetails?.images.length
                              ]
                            }
                            onCloseRequest={() => setLightboxIsOpen(false)}
                            onMovePrevRequest={() =>
                              setLightboxImageIndex(
                                (lightboxImageIndex +
                                  suggestionDetails?.images.length -
                                  1) %
                                  suggestionDetails?.images.length
                              )
                            }
                            onMoveNextRequest={() =>
                              setLightboxImageIndex(
                                (lightboxImageIndex + 1) %
                                  suggestionDetails?.images.length
                              )
                            }
                          />
                        )}
                      </>
                    </Col>
                    <Col lg={6} sm={6}>
                      <div className="productDetail-content">
                        <Row>
                          <Col lg={9} sm={9} xs={9}>
                            <h4>{suggestionDetails?.name}</h4>
                          </Col>
                          <Col lg={3} sm={3} xs={3}>
                            <p>
                              {suggestionDetails?.veg == 0 ? (
                                <span>
                                  <span className="non-vegetarian">●</span>
                                </span>
                              ) : (
                                <span>
                                  <span className="vegetarian">●</span>
                                </span>
                              )}
                            </p>
                          </Col>
                        </Row>
                        <p>
                          By <span>{suggestionDetails?.store_name}</span>
                        </p>
                        <Wrapper>
                          <div className="icon-style">
                            {ratingStarSuggetstion}
                            <p>
                              ({suggestionDetails?.rating_count} customer
                              reviews)
                            </p>
                          </div>
                        </Wrapper>

                        <div className="needplaceProduct">
                          <Row>
                            <Col sm={6} xs={6}>
                              <div>
                                <div>
                                  <div className="tab-container">
                                    <h6>Variations</h6>
                                    <Row>
                                      {suggestionDetails?.variations &&
                                        suggestionDetails?.variations?.length >
                                          0 &&
                                        suggestionDetails?.variations.map(
                                          (item, index) => (
                                            <Col
                                              lg={5}
                                              key={index}
                                              className="p-0"
                                            >
                                              {item.stock !== 0 ? (
                                                <div
                                                  className={`tab-variations ${
                                                    selectedVariant ===
                                                    item.type
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  onClick={() => {
                                                    setSelectedVariant(
                                                      item.type
                                                    );
                                                    setSelectedVariantPrice(
                                                      item.price
                                                    ); // Store the price in state
                                                  }}
                                                >
                                                  {item.type}
                                                </div>
                                              ) : (
                                                <div
                                                  className="tab-variations disabledvariation"
                                                  title="Stock unavailable"
                                                >
                                                  {/* <span className="blurred-text"> */}
                                                  {item.type}
                                                  {/* </span> */}
                                                </div>
                                              )}
                                            </Col>
                                          )
                                        )}
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col sm={6} xs={6}>
                              <div className="quantity-btn quickbtn">
                                <button onClick={handleDecrementone}>
                                  <i className="fa fa-minus" />
                                </button>
                                <form>
                                  <div className="form-group">
                                    <input
                                      type="tel"
                                      className="form-control"
                                      placeholder="Quantity"
                                      value={quantity}
                                      onChange={handleQuantityChange}
                                      autoComplete="new-number"
                                    />
                                  </div>
                                </form>
                                <button onClick={handleIncrementone}>
                                  <i className="fa fa-plus" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="needplaceProduct">
                          <div className="product-deatils-price">
                            {uservariationprice && formattedAmount >= 0 ? (
                              <Row>
                                <Col lg={3} sm={3} xs={3}>
                                  <p>{`₹${uservariationprice}`}</p>
                                </Col>
                                <Col lg={4} sm={4} xs={3}>
                                  <h5>{`₹${
                                    isNaN(formattedAmount) ? 0 : formattedAmount
                                  }`}</h5>
                                </Col>
                                {/* {formattedSavedAmount > 0 && ( */}
                                <Col lg={5} sm={5} xs={3}>
                                  {formattedSavedAmount > 0 ? (
                                    <h6>You save ₹{formattedSavedAmount}</h6>
                                  ) : (
                                    <h6>No savings</h6>
                                  )}
                                </Col>
                                {/* )} */}
                              </Row>
                            ) : (
                              <Row>
                                <Col lg={4} sm={4} xs={3}>
                                  <h5>{`₹${
                                    isNaN(MrpPrice) ? 0 : MrpPrice
                                  }`}</h5>
                                </Col>
                              </Row>
                            )}
                          </div>
                          <Row>
                            <Col lg={5} sm={5} xs={4}>
                              <p>(inclusive of all taxes)</p>
                            </Col>
                          </Row>
                        </div>
                        <h5>About Us</h5>
                        {productDetails ? (
                          <Table responsive>
                            <tbody>
                              <tr>
                                <th>Brand</th>
                                <td>{suggestionDetails?.brand_id}</td>
                              </tr>
                              <tr>
                                <th>Age Range</th>
                                <td>{suggestionDetails?.lifeStage_id}</td>
                              </tr>
                              <tr>
                                <th>Target Species</th>
                                <td>{suggestionDetails?.Petsbreeds_id}</td>
                              </tr>
                            </tbody>
                          </Table>
                        ) : (
                          <p>No data available for this product.</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {suggestionDetails?.stock &&
                  suggestionDetails?.stock?.length !== 0 ? (
                    <div className="productBTNaddcard">
                      {customerLoginId === null ? (
                        <Button data-dismiss="modal">
                          {/* <Button> */}
                          <Link
                            onClick={() => {
                              const filterData = cart.filter((el) => {
                                console.log("elll: ", el);
                                return el.item_id === suggestionDetails?.id;
                              });
                              if (filterData?.length > 0) {
                                toast.error("Already in added");
                              } else {
                                dispatch({
                                  type: "ADD_TO_CART",
                                  payload: {
                                    item_id: suggestionDetails?.id,
                                    variant: selectedVariant,
                                    price:
                                      calculatedPrice === 0
                                        ? parseInt(suggestionDetails?.price) *
                                          quantity
                                        : parseInt(calculatedPrice),
                                    quantity: quantity,
                                    item_name: suggestionDetails?.name,
                                    image: suggestionDetails?.image,
                                    orderamountwithquantity: formattedAmount,
                                  },
                                });
                              }
                            }}
                          >
                            <i className="fa fa-shopping-bag" /> Add to cart
                          </Link>
                          <p>{addToCartStatus}</p>
                        </Button>
                      ) : (
                        <Button>
                          <Link
                            to={`/cart/${suggestionDetails?.id}`}
                            onClick={handleAddToCart}
                          >
                            <i className="fa fa-shopping-bag" /> Add to cart
                          </Link>
                          <p>{addToCartStatus}</p>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="sold-out-btn mt-3">
                      <Link>Sold Out</Link>
                      <br />
                      <Button data-toggle="modal" data-target="#soldoutModel">
                        Notify Me When Available
                      </Button>
                    </div>
                  )}
                </Container>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* suggestion quick view modal end */}
      {/* buynow-model */}
      <div
        className="modal fade buynow"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <>
                <Container>
                  <div className="needplace">
                    <div className="address">
                      <h3>Address</h3>
                      <div className="address-card">
                        {addresslist && addresslist.length > 1 ? (
                          addresslist.map(
                            (item, index) =>
                              index === 0 && (
                                <p key={item.id}>
                                  {item.house_no} {item.area} {item.landmark}{" "}
                                  {item.city} {item.state} {item.pincode}
                                </p>
                              )
                          )
                        ) : (
                          <p>No data to display</p>
                        )}
                      </div>
                    </div>
                  </div>
                </Container>
                <Container>
                  <div className="needplace">
                    <div className="address">
                      <h3>Shipping Address</h3>
                      <div className="address-card">
                        <Row>
                          <Col lg={10} sm={9}>
                            {selectedAddress ? (
                              <div className="selectedAddress-area">
                                <p>
                                  {selectedAddress.first_name}{" "}
                                  {selectedAddress.last_name}
                                </p>
                                <p>
                                  {selectedAddress.house_no}{" "}
                                  {selectedAddress.area}{" "}
                                  {selectedAddress.landmark}{" "}
                                  {selectedAddress.city} {selectedAddress.state}{" "}
                                  {selectedAddress.pincode}
                                </p>
                                <p>Mobile: {selectedAddress.mobile}</p>
                              </div>
                            ) : (
                              <p>No address selected</p>
                            )}
                          </Col>
                          <Col lg={2} sm={3}>
                            <Button
                              data-toggle="modal"
                              data-target="#changeadress-model"
                            >
                              Add
                            </Button>
                          </Col>
                          <Col lg={12} sm={12}>
                            <div className="address-arrow">
                              <button onClick={toggleAddressContent}>
                                Select Address{" "}
                                <i
                                  className={`fa ${
                                    addressContentVisible
                                      ? "fa-arrow-up"
                                      : "fa-arrow-down"
                                  }`}
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                            <br />
                            <Row>
                              {addressContentVisible && (
                                <Col lg={12}>
                                  <div className="address-Content">
                                    {addresslist && addresslist.length > 0 ? (
                                      addresslist.map((item, index) => (
                                        <div
                                          className="chk-address"
                                          key={item.id}
                                        >
                                          <div className="chk-center">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="exampleRadios"
                                              onClick={() =>
                                                handleAddressClick(index)
                                              }
                                            />
                                          </div>
                                          <div className="Daynamic-address">
                                            <table>
                                              <tr>
                                                <th>Name:&nbsp;</th>
                                                <td>
                                                  {item.first_name}&nbsp;
                                                  {item.last_name}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Address:&nbsp;</th>
                                                <td>
                                                  {item.house_no} {item.area}{" "}
                                                  {item.landmark} {item.city}{" "}
                                                  {item.state} {item.pincode}
                                                </td>
                                              </tr>
                                              <tr>
                                                <th>Mobile:&nbsp;</th>
                                                <td>{item.mobile}</td>
                                              </tr>
                                            </table>
                                            <div className="address-delete">
                                              <i
                                                className="fa fa-trash"
                                                onClick={() =>
                                                  handleDeleteAddress(item.id)
                                                }
                                              />
                                              &nbsp; &nbsp;
                                              <i
                                                className="fa fa-edit"
                                                data-toggle="modal"
                                                onClick={() => {
                                                  setProfileData(item);
                                                }}
                                                data-target="#update-model"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    ) : (
                                      <p>No Addresses Available</p>
                                    )}
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Container>
                {/* {productDetails && productDetails.length > 0 ? ( */}
                <section className="section-padding">
                  <Container>
                    <Row>
                      <Col lg={3}>
                        <img
                          src={
                            "https://admin.canineproducts.in/storage/app/public/product/" +
                            productDetails?.image
                          }
                        />
                      </Col>
                      <Col lg={7} sm={10}>
                        <h2>{productDetails?.name}</h2>
                        <div className="tab-container">
                          <h6>Variations</h6>
                          <Row>
                            {productDetails?.variations &&
                              productDetails?.variations.length > 0 &&
                              productDetails?.variations.map((item, index) => (
                                <Col lg={3} key={index}>
                                  {item.stock !== 0 ? (
                                    <div
                                      className={`tab-variations ${
                                        selectedVariant === item.type
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setSelectedVariant(item.type);
                                        setSelectedVariantPrice(item.price); // Store the price in state
                                      }}
                                    >
                                      {item.type}
                                    </div>
                                  ) : (
                                    <div
                                      className="tab-variations disabledvariation"
                                      title="Stock unavailable"
                                    >
                                      {/* <span className="blurred-text"> */}
                                      {item.type}
                                      {/* </span> */}
                                    </div>
                                  )}
                                </Col>
                              ))}
                          </Row>
                        </div>
                        {/* <h3>{`₹${parseInt(buynowformattedAmount)}`}</h3>
                        <div className="quantity-btn quickbtn">
                          <button onClick={handleDecrementbuynow}>
                            <i className="fa fa-minus" />
                          </button>
                          <form>
                            <div className="form-group">
                              <input
                                type="tel"
                                className="form-control"
                                placeholder="Quantity"
                                value={quantitybuynow}
                                onChange={handleQuantityChangebuynow}
                                autoComplete="new-number"
                              />
                            </div>
                          </form>
                          <button onClick={handleIncrementbuynow}>
                            <i className="fa fa-plus" />
                          </button>
                        </div> */}
                        <div className="quantity-btn quickbtn">
                          <button onClick={handleDecrementone}>
                            <i className="fa fa-minus" />
                          </button>
                          <form>
                            <div className="form-group">
                              <input
                                type="tel"
                                className="form-control"
                                placeholder="Quantity"
                                value={quantity}
                                onChange={handleQuantityChange}
                                autoComplete="new-number"
                              />
                            </div>
                          </form>
                          <button onClick={handleIncrementone}>
                            <i className="fa fa-plus" />
                          </button>
                          <p>(inclusive of all taxes)</p>
                        </div>

                        <div className="needplaceProduct">
                          <div className="product-deatils-price">
                            {uservariationprice && formattedAmount >= 0 ? (
                              <Row>
                                <Col lg={3} sm={3} xs={3}>
                                  <p>{`₹${uservariationprice}`}</p>
                                </Col>
                                <Col lg={4} sm={4} xs={3}>
                                  <h5>{`₹${
                                    isNaN(formattedAmount) ? 0 : formattedAmount
                                  }`}</h5>
                                </Col>
                                {/* {formattedSavedAmount > 0 && ( */}
                                <Col lg={5} sm={5} xs={3}>
                                  {formattedSavedAmount > 0 ? (
                                    <h6>You save ₹{formattedSavedAmount}</h6>
                                  ) : (
                                    <h6>No savings</h6>
                                  )}
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col lg={4} sm={4} xs={3}>
                                  <h5>{`₹${
                                    isNaN(MrpPrice) ? 0 : MrpPrice
                                  }`}</h5>
                                </Col>
                              </Row>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col lg={2} sm={2} xs={6} className="align-self-end">
                        <div className="delete-addcard">
                          <Link onClick={() => removeFromCart(item.id)}>
                            <i class="fa fa-trash-o" />
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                  </Container>
                </section>
                {/* ) : (
                  <section className="section-padding">
                    <Container
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Row>
                        <p>Cart is Empty</p>
                      </Row>
                    </Container>
                  </section>
                )} */}
                <Container>
                  <div className="needplace">
                    <Row className="justify-content-center">
                      <Col lg={10}>
                        {!coupencode ? (
                          <div class="card mb-3">
                            <div class="card-body">
                              <form>
                                <div class="form-group">
                                  <label>Have a Coupon Code?</label>
                                  <div class="input-group ">
                                    <input
                                      type="text"
                                      class="form-control coupon"
                                      name=""
                                      placeholder="Coupon code"
                                      data-toggle="modal"
                                      data-target="#Coupon"
                                    />
                                    {/* <span class="input-group-append px-3">
                                  <button
                                    onClick={() => {
                                      setcoupenCode(!coupencode);
                                    }}
                                    class="btn btn-primary btn-apply coupon"
                                    data-toggle="modal"
                                    data-target="#Coupon"
                                  >
                                    Apply
                                  </button>
                                </span> */}
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        ) : (
                          <div className="add-cart-Voucher ">
                            <Row>
                              <Col>
                                <img src={voch} />
                              </Col>
                              <Col className="align-self-center">
                                <h5>{disscountvalue?.title}</h5>
                              </Col>
                              <Col className="align-self-center">
                                <h6>₹{disscountvalue?.discount}</h6>
                              </Col>
                              <Col className="align-self-center">
                                <button
                                  // onClick={() => {
                                  //   setcoupenCode(!coupencode);
                                  // }}
                                  onClick={clearCoupon}
                                  type="button"
                                  class="btn btn-danger"
                                >
                                  X
                                </button>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Container>

                <Container>
                  <div className="needplace">
                    <Row className="justify-content-center">
                      <Col lg={10}>
                        <div className="add-cart-total">
                          <Row>
                            <Col>
                              <h5>Sub Total</h5>
                            </Col>
                            <Col>
                              {/* <h5>₹{addToCartProduct[0]?.price}</h5> */}
                              <h5>₹{parseInt(Amount)}</h5>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col>
                              <h5>Coupon Discount</h5>
                            </Col>
                            <Col>
                              <h5>
                                ₹
                                {appliedCoupon
                                  ? parseInt(disscountvalue?.discount)
                                  : 0}
                              </h5>
                            </Col>
                          </Row>
                          <hr />
                          {/* <Row>
                            <Col>
                              <h5>Tax(5%)</h5>
                            </Col>
                            <Col>
                              <h5>{`₹${Math.floor(Amount * 0.05)}`}</h5>
                            </Col>
                          </Row>
                          <hr /> */}

                          <Row>
                            <Col>
                              <h5>Rounding Adjust</h5>
                            </Col>
                            <Col>
                              <h5>
                                ₹
                                {/* {parseInt(Amount) * 0.05 +
                                  parseInt(Amount) -
                                  (disscountvalue?.discount ?? 0)} */}
                                {parseInt(totalPrice)}
                              </h5>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
                <div className="homecheckout">
                  <button
                    data-toggle="modal"
                    data-target="#cod"
                    disabled={!isAddressSelected}
                  >
                    Checkout
                  </button>
                  <button data-dismiss="modal" onClick={handleResetClick}>
                    Close
                  </button>
                  {isAddressSelected ? null : (
                    <div className="error-message">
                      Please Select Shipping Address.
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade editAddress"
        id="changeadress-model"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                New Address Add
              </h5>
            </div>
            <div className="modal-body">
              <div class="form-group">
                <label>First Name</label>
                <input
                  class="form-control"
                  type="text"
                  value={first_name}
                  onChange={(e) => {
                    setfirst_name(e.target.value);
                    validateForm();
                  }}
                  onBlur={() => {
                    if (first_name.trim() === "") {
                      setFirst_nameError("First Name is required");
                    } else {
                      setFirst_nameError("");
                    }
                  }}
                />
                <span style={{ color: "red" }}>{first_nameError}</span>
              </div>
              <div class="form-group">
                <label>Last Name</label>
                <input
                  class="form-control"
                  type="text"
                  value={last_name}
                  onChange={(e) => {
                    setlast_name(e.target.value);
                    validateForm();
                  }}
                  onBlur={() => {
                    if (last_name.trim() === "") {
                      setLast_nameError("Last Name is required");
                    } else {
                      setLast_nameError("");
                    }
                  }}
                />
                <span style={{ color: "red" }}>{last_nameError}</span>
              </div>
              <div class="form-group">
                <label>Mobile</label>
                <input
                  type="tel"
                  name="mobile"
                  class="form-control"
                  maxLength={10}
                  value={mobile}
                  onChange={(e) => {
                    setmobile(e.target.value);
                    validateForm();
                    const numericValue = e.target.value.replace(/[^0-9+]/g, ""); // Remove non-numeric character
                    if (numericValue.length <= 10) {
                      setmobile(numericValue);
                    }
                  }}
                  onBlur={() => {
                    if (mobile.trim() === "") {
                      setMobileError("Mobile Number is required");
                    } else {
                      setMobileError("");
                    }
                  }}
                />
                <span style={{ color: "red" }}>{mobileError}</span>
              </div>
              <div class="form-group">
                <label>flat,House no,Building,Company</label>
                <input
                  class="form-control"
                  type="text"
                  value={house_no}
                  onChange={(e) => {
                    sethouse_no(e.target.value);
                    validateForm();
                  }}
                  onBlur={() => {
                    if (house_no.trim() === "") {
                      setHouse_noError(
                        "House no, flat, Building, Company Number is required"
                      );
                    } else {
                      setHouse_noError("");
                    }
                  }}
                />
                <span style={{ color: "red" }}>{house_noError}</span>
              </div>
              <div class="form-group">
                <label>Area, Street,Sector,Village</label>
                <input
                  class="form-control"
                  type="text"
                  value={area}
                  onChange={(e) => {
                    setarea(e.target.value);
                    validateForm();
                  }}
                  onBlur={() => {
                    if (area.trim() === "") {
                      setAreaError("Area, Street, Sector, Village is required");
                    } else {
                      setAreaError("");
                    }
                  }}
                />
                <span style={{ color: "red" }}>{areaError}</span>
              </div>
              <div class="form-group">
                <label>Landmark</label>
                <input
                  class="form-control"
                  type="text"
                  value={landmark}
                  onChange={(e) => {
                    setlandmark(e.target.value);
                    validateForm();
                  }}
                  onBlur={() => {
                    if (landmark.trim() === "") {
                      setLandmarkError("Landmark is required");
                    } else {
                      setLandmarkError("");
                    }
                  }}
                />
                <span style={{ color: "red" }}>{landmarkError}</span>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>State</label>
                    <select
                      className="form-control"
                      onChange={Subscription}
                      value={state}
                      onInput={(e) => {
                        setstate(e.target.value);
                        validateForm();
                      }}
                      onBlur={() => {
                        if (state.trim() === "") {
                          setStateError("State is required");
                        } else {
                          setStateError("");
                        }
                      }}
                    >
                      <option>State Choose...</option>
                      {stateall.map((items) => (
                        <option value={items.id} key={items.id}>
                          {items.state_name}
                        </option>
                      ))}
                    </select>
                    <span style={{ color: "red" }}>{stateError}</span>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>City</label>
                    <select
                      className="form-control"
                      onInput={(e) => setSelectedCity(e.target.value)}
                      value={selectedCity}
                      onChange={(e) => {
                        setcity(e.target.value);
                        validateForm();
                      }}
                      onBlur={() => {
                        if (city.trim() === "") {
                          setCityError("City is required");
                        } else {
                          setCityError("");
                        }
                      }}
                    >
                      <option>City Choose...</option>
                      {stateallCity.map((items) => (
                        <option>{items.city_name}</option>
                      ))}
                    </select>
                    <span style={{ color: "red" }}>{cityError}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleFormControlInput1">Pincode</label>
                <input
                  class="form-control"
                  type="text"
                  value={pincode}
                  onChange={(e) => {
                    setpincode(e.target.value);
                    validateForm();
                  }}
                  onBlur={() => {
                    if (pincode.trim() === "") {
                      setPincodeError("Pincode is required");
                    } else {
                      setPincodeError("");
                    }
                  }}
                />
                <span style={{ color: "red" }}>{pincodeError}</span>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAddAddress}
                data-dismiss="modal"
                disabled={
                  !isFormValid ||
                  first_name.trim() === "" || // Add this condition
                  last_name.trim() === "" || // Add similar conditions for other fields
                  mobile.trim() === "" ||
                  house_no.trim() === "" ||
                  area.trim() === "" ||
                  landmark.trim() === "" ||
                  state.trim() === "" ||
                  selectedCity.trim() === "" ||
                  pincode.trim() === ""
                }
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* update-model */}
      <div
        className="modal fade editAddress"
        id="update-model"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Update Address
              </h5>
            </div>
            <div className="modal-body">
              <div class="form-group">
                <label>First Name</label>
                {/* <input
                  class="form-control"
                  type="text"
                  name="first_name"
                  value={profileData.first_name || ""}
                  onChange={(e) =>
                    setProfileData({
                      ...profileData,
                      first_name: e.target.value.replace(/[^A-Za-z]/, ""),
                    })
                  }
                /> */}
                <input
                  className="form-control"
                  type="text"
                  name="first_name"
                  value={profileData.first_name || ""}
                  onChange={(e) =>
                    setProfileData({
                      ...profileData,
                      first_name: e.target.value,
                    })
                  }
                />
              </div>
              <div class="form-group">
                <label>Last Name</label>
                <input
                  class="form-control"
                  type="text"
                  placeholder="Enter last name"
                  name="last_name"
                  value={profileData.last_name || ""}
                  onChange={(e) =>
                    setProfileData({
                      ...profileData,
                      last_name: e.target.value,
                    })
                  }
                />
              </div>
              <div class="form-group">
                <label>Mobile</label>
                <input
                  type="tel"
                  name="mobile"
                  class="form-control"
                  maxLength={10}
                  value={profileData.mobile || ""}
                  onChange={(e) =>
                    setProfileData({
                      ...profileData,
                      mobile: e.target.value
                        .replace(/\D/g, "")
                        .substring(0, 10),
                    })
                  }
                />
              </div>
              <div class="form-group">
                <label>flat,House no,Building,Company</label>
                <input
                  class="form-control"
                  type="text"
                  name="house_no"
                  value={profileData.house_no || ""}
                  onChange={(e) =>
                    setProfileData({
                      ...profileData,
                      house_no: e.target.value,
                    })
                  }
                />
              </div>
              <div class="form-group">
                <label>Area, Street,Sector,Village</label>
                <input
                  class="form-control"
                  type="text"
                  name="area"
                  value={profileData.area || ""}
                  onChange={(e) =>
                    setProfileData({
                      ...profileData,
                      area: e.target.value,
                    })
                  }
                />
              </div>
              <div class="form-group">
                <label>Landmark</label>
                <input
                  class="form-control"
                  type="text"
                  name="landmark"
                  value={profileData.landmark || ""}
                  onChange={(e) =>
                    setProfileData({
                      ...profileData,
                      landmark: e.target.value,
                    })
                  }
                />
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>State</label>
                    <select
                      className="form-control"
                      onChange={Subscription}
                      value={profileData.state || ""}
                      // onChange={(e) =>
                      // setProfileData ({
                      //   ...profileData,
                      //   state: e.target.value,
                      // })}
                    >
                      <option value="">State Choose...</option>
                      {stateall.map((items) => (
                        <option value={items.state_name} key={items.id}>
                          {items.state_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>City</label>
                    <select
                      className="form-control"
                      onInput={(e) => setSelectedCity(e.target.value)}
                      value={profileData.city || ""}
                      onChange={(e) =>
                        setProfileData({
                          ...profileData,
                          city: e.target.value,
                        })
                      }
                    >
                      <option value="">City Choose...</option>
                      {stateallCity.map((items) => (
                        <option value={items.city_name} key={items.id}>
                          {items.city_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleFormControlInput1">Pincode</label>
                <input
                  class="form-control"
                  type="text"
                  value={profileData.pincode || ""}
                  onChange={(e) =>
                    setProfileData({
                      ...profileData,
                      pincode: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleFormSubmit}
                data-dismiss="modal"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Coupon */}
      <div
        className="modal fade notification-area"
        id="Coupon"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h5>Coupon List</h5>
              {couponlist && couponlist.length > 0 ? (
                couponlist.map((item, index) => (
                  <div className="notification" key={index}>
                    <Row>
                      <Col lg={12}>
                        <table>
                          <tbody>
                            <tr>
                              <th>Title : </th>
                              <td>{item.title}</td>
                            </tr>
                            <tr>
                              <th>Code : </th>
                              <td>{item.code}</td>
                            </tr>
                            <tr>
                              <th>Discount Type : </th>
                              <td>{item.discount_type}</td>
                            </tr>
                            <tr>
                              <th>Discount : </th>
                              <td>{item.discount}</td>
                            </tr>
                            <tr>
                              <th>Min Purchase : </th>
                              <td>{item.min_purchase}</td>
                            </tr>
                            <tr>
                              <th>Max Discount : </th>
                              <td>{item.max_discount}</td>
                            </tr>
                            <tr>
                              <th>Start Date : </th>
                              <td>{item.start_date}</td>
                            </tr>
                            <tr>
                              <th>Expire Date : </th>
                              <td>{item.expire_date}</td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="text-center">
                          <button
                            // onClick={() => {
                            //   setcoupenCode(!coupencode);
                            // }}
                            onClick={(e) => coupendisscount(item)}
                            type="button"
                            className="btn btn-primary btn-apply coupon"
                            // data-toggle="modal"
                            // data-target="#Coupon"
                            data-dismiss="modal"
                          >
                            Apply
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <p className="emptyMSG">No Coupon List.</p>
              )}

              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
            {/* <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Save changes</button>
          </div> */}
          </div>
        </div>
      </div>
      {/* cod */}
      <div
        className="modal fade"
        id="cod"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="payment-done">
                <div className="select-card select-card3">
                  <div className="selct-card-text">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      data-dismiss="modal"
                      onClick={() => handlePayment()}
                    />
                    <p>Online Payment</p>
                  </div>
                </div>
                <div className="select-card select-card3">
                  <div className="selct-card-text">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="exampleRadios"
                      value="second"
                      checked={selectedInput}
                      onChange={handleRadioChange}
                    />
                    <p>Cash On Delivery</p>
                  </div>
                </div>
                <Button
                  disabled={!selectedInput}
                  data-toggle="modal"
                  data-target="#paysubmit"
                  data-dismiss="modal"
                >
                  <Link>pay</Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* paysubmit */}
      <div
        className="modal fade"
        id="paysubmit"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="payment-done">
                <img src={paydone} />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesettingLorem Ipsum is simply dummy text of the printing
                  and typesetting
                </p>
                <Button
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleSendRequest}
                >
                  <Link to="/shipping">Done</Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const Wrapper = styled.section`
  justify-content: flex-start;

  icon {
    font-size: 2rem;
    color: orange;
  }
  .emty-icon {
    font-size: 2.6rem;
  }
  p {
    margin: 0;
    padding-left: 1.2rem;
  }
`;

export default Productdetail;
